import Cookies from 'js-cookie';

export function detectChromeMobile() {
  const userAgent = navigator.userAgent;

  const isMobile = /Android|iPhone|iPad|iPod/i.test(userAgent);
  if (!isMobile) return false;

  // Chrome on iOS
  if (/CriOS/.test(userAgent)) return true;

  // Chrome on Android
  if (/Chrome/.test(userAgent) && /Android/.test(userAgent)) return true;

  return false;
}

export function getMobileFlags() {
  const isIphone = /iPhone/i.test(navigator.userAgent);
  const isChromeMobile = detectChromeMobile();
  const mobileApp = Cookies.get('bannerMobileApp');
  return {
    isIphone,
    isChromeMobile,
    mobileApp,
  };
}

export function isMobile() {
  const mobileApp = Cookies.get('bannerMobileApp');
  if (mobileApp) return true;

  const userAgent = navigator.userAgent;

  const isMobile = /Android|iPhone|iPad|iPod/i.test(userAgent);
  if (isMobile) return true;

  return false;
}
