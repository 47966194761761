import 'swiper/swiper-bundle.min.css';
import 'rc-slider/assets/index.css';
import 'aos/dist/aos.css';
import 'react-loading-skeleton/dist/skeleton.css';

/** @type {{[key: string]: number}}*/
export const BREAKPOINTS = {};

(() => {
  BREAKPOINTS[(BREAKPOINTS.xs = 375)] = 'xs';
  BREAKPOINTS[(BREAKPOINTS['xs+'] = 768)] = 'xs+';
  BREAKPOINTS[(BREAKPOINTS.sm = 834)] = 'sm';
  BREAKPOINTS[(BREAKPOINTS['sm+'] = 1024)] = 'sm+';
  BREAKPOINTS[(BREAKPOINTS.md = 1194)] = 'md';
  BREAKPOINTS[(BREAKPOINTS.xl = 1440)] = 'xl';
})();

const theme = {
  breakpoints: {
    [BREAKPOINTS.xs]: `${BREAKPOINTS.xs}px`,
    [BREAKPOINTS['xs+']]: `${BREAKPOINTS['xs+']}px`,
    [BREAKPOINTS.sm]: `${BREAKPOINTS.sm}px`,
    [BREAKPOINTS['sm+']]: `${BREAKPOINTS['sm+']}px`,
    [BREAKPOINTS.md]: `${BREAKPOINTS.md}px`,
    [BREAKPOINTS.xl]: `${BREAKPOINTS.xl}px`,
  },
  paddings: {
    container: {
      xs: '16px',
      'xs+': '40px',
      sm: '40px',
      'sm+': '40px',
      md: '40px',
      xl: '108px',
    },
  },
  colors: {
    'Deep Sea Coral': '#F25E53',
    'Deep Sea Coral Hover': '#FF7469',
    'Deep Sea Coral Pressed': '#D95147',
    'Moonlit Ocean': '#2D4459', // this color also applied to Landing.tsx <meta name="theme-color" content="#2d4459" />
    Jet: '#2D2D2D',
    Mindnight: '#161F26',
    'Paper White': '#E2E1EA',
    'Anti Flash White': '#F3F3F7',
    Black: '#000',
    White: '#FFF',
    'Hero Gradient': 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #F3F3F7 100%)',
    'Wrapper Box Shadow': '0px 0px 64px 0px rgba(45, 68, 89, 0.05)',
    'Cadet gray': '#96A1AC',
  },
  fontFamily: {
    Lexend: 'Lexend',
    Inter: 'Inter',
  },
  fontSizes: {
    '14px': '14px',
    '16px': '16px',
    '20px': '20px',
    '24px': '24px',
    '32px': '32px',
    '48px': '48px',
    '40px': '40px',
    '72px': '72px',
  },
  lineHeights: {
    '19.36px': '19.36px',
    '24px': '24px',
    '32px': '32px',
    '40px': '40px',
    '48px': '48px',
    '64px': '64px',
    '90px': '90px',
  },
  transitions: { transition: (s = 0.2) => `all ease-out ${s}s` },
  heights: {},
};
export default theme;
