/* eslint-disable import/no-import-module-exports */
import Cookies from 'js-cookie';
import './util/axios-wrapper';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';
import * as serviceWorker from './libraries/serviceWorker';
import { Auth0Provider } from './libraries/react-auth0-spa';
import config from './config.json';
import { ErrorBoundary } from './components/ErrorBoundary';
import { WaiterForUseEffect } from './components/app-layout/waitForUseEffect';
import { monkeyPatchConsole } from './util/console-monkey-patch';
import { getMobileFlags } from './util/detect-mobile';

monkeyPatchConsole();

const tagManagerArgs = {
  gtmId: 'GTM-WPRH9B5',
};

if (!process.env.DISABLE_GOOGLETAG) {
  TagManager.initialize(tagManagerArgs);
}

if (module.hot) module.hot.accept(); // so Hot Module Replacement (HMR) will work // https://medium.com/netscape/d8b77eea61eb

const { isIphone, isChromeMobile, mobileApp } = getMobileFlags();

if (isIphone) document.documentElement.setAttribute('view-iphone', '');
if (isChromeMobile) document.documentElement.setAttribute('view-chrome-mobile', '');
if (mobileApp) document.documentElement.setAttribute('view-mobile-native-app', '');

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={
      window.location.origin === 'https://alpha.withbanner.com' ? config.clientIdAlpha : config.clientId
    }
    redirect_uri={window.location.origin}
    audience={config.audience}
    cacheLocation="localstorage"
  >
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
    <WaiterForUseEffect /* ideally not nested in <ErrorBoundary /> so it never gets unmounted */ />
  </Auth0Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
